<template>
  <div class="center">
    <div class="header_title">服务商信息</div>
    <div class="company">
      <div class="name">{{ alldata.providerName }}</div>
      <div class="tell">
        <i class="hy-icon-dianhua"></i> {{ alldata.providerTelephone }}
      </div>
    </div>
    <div class="header_title">订单信息</div>
    <div class="order">
      <div class="left">
        <img src="@/assets/images/dingdan.jpg" />
      </div>
      <div class="table">
        <el-descriptions class="margin-top" :column="3" size="medium" border>
          <el-descriptions-item label-class-name="my-label">
            <template slot="label"> 状态： </template>
            {{ transportOrderStatus[alldata.transportOrderStatus] }}
          </el-descriptions-item>
          <el-descriptions-item :span="2" label-class-name="label_right">
            <template slot="label"> 发货量： </template>
            {{ alldata.num }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label">
            <template slot="label"> 服务商接单时间： </template>
            {{ alldata.receiveOrderTime }}
          </el-descriptions-item>
          <el-descriptions-item :span="2" label-class-name="label_right">
            <template slot="label"> 货物名称： </template>
            {{ alldata.goodName }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label">
            <template slot="label"> 付款时间： </template>
            {{ alldata.payTime }}
          </el-descriptions-item>
          <el-descriptions-item :span="2" label-class-name="label_right">
            <template slot="label"> 下单方确认服务完成时间： </template>
            {{ alldata.consumerSureTime }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label">
            <template slot="label"> 支付订单总金额： </template>
            {{ alldata.orderPayAmount }}
          </el-descriptions-item>
          <el-descriptions-item :span="2" label-class-name="label_right">
            <template slot="label"> 服务商确认完成时间： </template>
            {{ alldata.providerSureTime }}
          </el-descriptions-item>
          <el-descriptions-item :span="3" label-class-name="my-label">
            <template slot="label"> 起始地： </template>
            {{ alldata.endAddressCode||''+alldata.startAddress }}
          </el-descriptions-item>
          <el-descriptions-item :span="3" label-class-name="my-label">
            <template slot="label"> 目的地： </template>
            {{ alldata.startAddressCode||''+alldata.endAddress }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="header_title">车辆信息</div>
    <div class="car">
      <div class="left">
        <img src="@/assets/images/car.jpg" />
      </div>
      <div class="right">
        <div
          :key="it.serviceCarId"
          v-for="it in alldata.serviceCars"
          class="item"
        >
          车牌号：<span>{{ it.plateNo }}</span>
        </div>
      </div>
    </div>
    <div class="header_title">关联的服务信息</div>
    <div class="info">
      <div class="relation">
        <div class="left pointer"  @click="todetail">
          <div class="img">
            <img class="img" :src="alldata.mainGraph" />
          </div>
          <div class="every">
            <div class="first">{{ alldata.name }}</div>
            <div class="secend">
              服务类别：{{ alldata.serviceType }} | 单价：{{
                alldata.goodsPrice + alldata.unit
              }}
            </div>
            <div class="thired">{{ alldata.createTime }}</div>
          </div>
        </div>
        <div class="right">
          <div class="tag">{{ alldata.onShelf ? "销售中" : "已下架" }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="
        alldata.transportOrderStatus == 1 || alldata.transportOrderStatus == 2
      "
      @click="toaplay(alldata)"
      class="bottom pointer"
    >
      去支付
    </div>
    <div
      v-if="alldata.transportOrderStatus == 0"
      @click="changestatus(5, alldata.serviceOrderId)"
      class="bottom pointer"
    >
      取消订单
    </div>
    <div
      v-if="alldata.transportOrderStatus == 2"
      @click="querycar(4, alldata.serviceOrderId, false)"
      class="bottom pointer"
    >
      确认服务完成
    </div>
    <el-dialog
      class="zyr_dialog"
      title="支付运费"
      :visible.sync="dialogVisiblezf"
      width="661px"
      :before-close="zfhandleClose"
    >
      <div class="dialog_center">
        <el-form
          :model="aplayform"
          :rules="rules"
          ref="dynamicValidateForm"
          label-width="150px"
          class="demo-dynamic"
        >
          <el-form-item label="运输数量：" prop="trafficCount">
            <el-input
              size="small"
              @input="
                aplayform.trafficCount = aplayform.trafficCount.replace(
                  /[^\d^\.]/g,
                  ''
                )
              "
              @blur="changeall"
              maxlength="5"
              placeholder="请输入运输数量"
              v-model="aplayform.trafficCount"
            ></el-input>
          </el-form-item>
          <el-form-item label="运输单价(元/吨)：" prop="trafficPrice">
            <el-input
              size="small"
              v-model="aplayform.trafficPrice"
              @input="
                aplayform.trafficPrice = aplayform.trafficPrice.replace(
                  /[^\d^\.]/g,
                  ''
                )
              "
              maxlength="5"
              placeholder="请输入单价"
              @blur="changeall"
            ></el-input>
          </el-form-item>
          <el-form-item label="需要支付金额：" prop="num">
            {{ "￥" + allprice }}
          </el-form-item>
          <el-form-item label="支付方式：" prop="num">
            <span>账户余额（余额：￥{{yue}}）</span>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="zfhandleClose">取 消</el-button>
        <el-button type="primary" @click="handleSure">立即支付</el-button>
      </span>
    </el-dialog>
    <passWord
      :isShow="passWordIsShow"
      title="输入支付密码"
      @close="passWordIsShow = false"
      @submit="submit"
    />
  </div>
</template>

<script>
import {
  detailServiceOrder,
  dealServiceOrder,
  applymenoy,
  queryyue,
} from "@/api/home";
import md5 from "js-md5";
import passWord from "@/components/InputPasswordDialog2.vue";
let uploadfoo = null;
const { baseUrl } = require("@/config/index.js");
import * as ESASignClient from "@/utils/signConfig/ESASignClient.js";
export default {
  components: {
    passWord,
  },
  data() {
    return {
      yue:'',
      passWordIsShow: false,
      rules: {
        trafficPrice: [
          { required: true, message: "请输入运输单价", trigger: "blur" },
        ],
        trafficCount: [
          { required: true, message: "请输入运输数量", trigger: "blur" },
        ],
      },
      transportOrderStatus: [
        "未接单",
        "待付款",
        "已付款",
        "已完成",
        "已拒绝",
        "已取消",
        "对方已确认",
        "等待对方确认",
      ],
      allprice: "0",
      aplayform: {
        trafficPrice: "",
        trafficCount: "",
      },
      form: {
        domains: [
          {
            carnum: "",
            key: Date.now(),
          },
        ],
      },
      dialogVisiblezf: false,
      dialogVisible: false,
      alldata: {},
    };
  },
  mounted() {
    this.getdetail();
  },
  methods: {
    queryYe() {
      queryyue().then((res) => {
        if (res.code == 0) {
          this.yue = res.data;
        }
      });
    },
    async querycar(operateType, serverOrderId, other) {
      let foo = { serviceOrderId: serverOrderId, operateType }
      other == 201 ? foo.confirmCar = true : other == 202 ? foo.confirmPay = true:''
      dealServiceOrder(foo)
      .then(
        (res) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.getdetail();
          } else if (res.code == 201||res.code == 202) {
            this.$confirm(res.message, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              customClass: "zzyss_confirm",
            })
            .then(e=>{
              this.querycar(operateType,serverOrderId,res.code)
            })
            .catch(e=>{
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            })
          }
        }
      );
    },
    changeall() {
      if (this.aplayform.trafficCount && this.aplayform.trafficPrice) {
        this.allprice =
          (Number(this.aplayform.trafficCount) *
            1000 *
            (Number(this.aplayform.trafficPrice) * 1000)) /
          1000000;
      }
    },
    zfhandleClose() {
      this.$refs.dynamicValidateForm.resetFields();
      this.dialogVisiblezf = false;
    },
    submit(payPassword) {
      applymenoy({
        serviceOrderId: uploadfoo,
        payAmount: this.allprice,
        payPassword:md5(payPassword),
      }).then((res) => {
        if (res.code == 0) {
          this.zfhandleClose();
          this.getdetail();
          this.$message.success("支付成功");
        }else{
          this.$message.error(res.message);
        }
      });
    },
    JsESACheckIsKeyk(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        this.passWordIsShow = true;
      } else {
        alert(msg);
        return false;
      }
    },
    handleSure() {
      this.$refs.dynamicValidateForm.validate((valid) => {
        if (valid) {
          ESASignClient.ESACheckIsKey(this.JsESACheckIsKeyk, null, false);
        }
      });
    },
    toaplay(ite) {
      if (!this.beforhandle('personalCenter:plogisticsOrder:ongoing:topay')) return;
      uploadfoo = ite.serviceOrderId;
      this.queryYe()
      this.dialogVisiblezf = true;
    },
    addfrom() {
      this.form.domains.push({
        carnum: "",
        key: Date.now(),
      });
    },
    delfrom(delfrom) {
      let index = this.form.domains.indexOf(delfrom);
      if (index !== -1) {
        this.form.domains.splice(index, 1);
      }
    },
    // handleSure() {
    //   let uploadfoo = {
    //     serviceOrderId: this.alldata.serviceOrderId,
    //     serviceOrderTransportRelationId:
    //       this.alldata.serviceOrderTransportRelationId,
    //   };
    //   console.log(uploadfoo);
    //   this.$refs.dynamicValidateForm.validate((valid) => {
    //     if (valid) {
    //       uploadfoo.carNums = this.form.domains.map((item) => {
    //         return item.carnum;
    //       });
    //       uploadTransportCars(uploadfoo).then((res) => {
    //         if (res.code == 0) {
    //           this.$message.success("上传成功");
    //           this.handleClose();
    //         }
    //       });
    //     } else {
    //       console.log("error submit!!");
    //       return false;
    //     }
    //   });
    // },
    uploadCar() {
      this.dialogVisible = true;
    },
    todetail() {
      this.$router.push({
        path: "/associateddetail",
        query: {
          id: this.alldata.serviceGoodId,
          type: 'other'
        },
      });
    },
    changestatus(operateType, serviceOrderId) {
      if (!this.beforhandle(['','','','','','personalCenter:plogisticsOrder:ongoing:cancel'][operateType])) return;
      const status = [
        "",
        "确认接单",
        "确认拒绝接单",
        "确认服务完成",
        "确认服务完成",
        "取消订单",
      ];
      this.$confirm(status[operateType], "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "zzyss_confirm",
      })
        .then(() => {
          dealServiceOrder({ serviceOrderId, operateType }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getdetail();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    handleClose() {
      this.form = {
        domains: [
          {
            carnum: "",
            key: Date.now(),
          },
        ],
      };
      this.dialogVisible = false;
    },
    getdetail() {
      detailServiceOrder(this.$route.query.id).then((res) => {
        if (res.code == 0) {
          this.alldata = {
            ...res.data.serviceOrderVO,
            ...res.data.transportInfo,
            mainGraph:
              baseUrl +
              "third/third/ossGetFile/" +
              res.data.serviceOrderVO.mainGraph,
            goodsPrice: res.data.serviceOrderVO.goodsPrice
              ? res.data.serviceOrderVO.goodsPrice
              : "面议",
            createTime: res.data.serviceOrderVO.createTime.replace("T", " "),
            finishTime: res.data.serviceOrderVO.finishTime
              ? res.data.serviceOrderVO.finishTime.replace("T", " ")
              : "",
            allprice:
              (res.data.transportInfo.num *
                100 *
                (res.data.transportInfo.price * 100)) /
              10000,
          };
          this.alldata.receiveOrderTime = this.changedata(
            this.alldata.receiveOrderTime
          );
          this.alldata.payTime = this.changedata(this.alldata.payTime);
          this.alldata.consumerSureTime = this.changedata(
            this.alldata.consumerSureTime
          );
          this.alldata.providerSureTime = this.changedata(
            this.alldata.providerSureTime
          );
        }
      });
    },
    changedata(value) {
      if (value) {
        return value.replace("T", " ");
      }
    },
  },
};
</script>
<style lang="scss">
.zzyss_confirm {
  .el-message-box {
    width: 450px !important;
    height: 218px !important;
    background: #ffffff !important;
    border-radius: 10px !important;
  }
  .el-message-box__header {
    background: #f8f8f8;
  }
  .el-message-box__title {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
  }
  .el-message-box__content {
    padding: 49px 119px;
    color: #606266;
    font-size: 14px;
  }
  .el-message-box__btns {
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
}
.zyr_dialog {
  .el-dialog {
    border-radius: 10px;
  }
  .el-form-item {
    margin-bottom: 5px;
  }
  .el-dialog__body {
    padding: 80px 150px 43px 150px;
  }
  .el-dialog__header {
    height: 53px;
    padding: 19px 25px;
    background: #f8f8f8;
    border-radius: 10px;
    .el-dialog__title {
      font-size: 16px;
      line-height: 16px;
      color: #666666;
    }
  }
  .el-dialog__footer {
    padding: 18px 25px 25px;
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
}
.zyq_dialog {
  .el-dialog {
    border-radius: 10px;
  }
  .el-dialog__header {
    height: 53px;
    padding: 19px 25px;
    background: #f8f8f8;
    border-radius: 10px;
    .el-dialog__title {
      font-size: 16px;
      line-height: 16px;
      color: #666666;
    }
  }
  .el-dialog__footer {
    padding: 18px 25px 25px;
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
  // input[type=text] {
  //   height: 34px;
  // }
  .demo-dynamic {
    width: 401px;
  }
  .el-input {
    width: 211px;
  }
  .dialog_center {
    display: flex;
    justify-content: center;
    height: 300px;
    overflow-y: auto;
  }
}
</style>
<style>
.my-label {
  width: 176px;
  text-align: right !important;
}
.label_right {
  width: 205px;
  text-align: right !important;
}
</style>
<style lang="scss" scoped>
.center {
  width: 1195px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  .bottom {
    width: 308px;
    margin: 0 auto 14px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
    background: linear-gradient(90deg, #2079fb, #4cb2fc);
    border-radius: 4px;
  }
  .info {
    .relation {
      display: flex;
      justify-content: space-between;
      padding: 17px;
      .left {
        display: flex;
        .img {
          width: 167px;
          height: 97px;
          border-radius: 2px;
        }
        .every {
          padding-left: 17px;
          .first {
            height: 20px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #40474e;
          }
          .secend {
            height: 15px;
            font-size: 14px;
            margin: 13px 0 31px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #40474e;
          }
          .thired {
            height: 9px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #787b80;
          }
        }
      }
      .right {
        .tag {
          width: 68px;
          height: 22px;
          text-align: center;
          line-height: 20px;
          color: #188827;
          border: 1px solid #188827;
          border-radius: 2px;
        }
      }
    }
  }
  .header_title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
    padding-left: 15px;
    height: 45px;
    line-height: 45px;
    background: #fafafa;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }
  .company {
    padding: 23px 13px 20px;
    .name {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .tell {
      margin-top: 15px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
    }
  }
  .order {
    display: flex;
    padding: 20px 0;
    .left {
      width: 267px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .table {
      width: 900px;
    }
  }
  .car {
    display: flex;
    padding: 25px 5px 8px 0;
    .left {
      min-width: 192px;
      max-width: 192px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-bottom: 17px;
      }
    }
    .right {
      .item {
        display: inline-block;
        text-align: center;
        width: 171px;
        height: 31px;
        margin-right: 28px;
        margin-bottom: 17px;
        font-size: 14px;
        line-height: 31px;
        color: #999999;
        border: 1px dashed #d2d2d2;
        border-radius: 16px;
        span {
          color: #333333;
        }
      }
    }
  }
}
</style>>